// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
    const onLoadScriptRef = useRef();

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_1b8e6') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: 980,
                        height: 610,
                        symbol: "FX:EURUSD",
                        interval: "5",
                        timezone: "Etc/UTC",
                        theme: "dark",
                        style: "1",
                        locale: "in",
                        toolbar_bg: "#f1f3f6",
                        enable_publishing: false,
                        hide_top_toolbar: true,
                        details: true,
                        container_id: "tradingview_1b8e6"
                    });
                }
            }
        },
        []
    );

    return (
        <div className='tradingview-widget-container'>
            <div id='tradingview_1b8e6' />

        </div>
    );
}
