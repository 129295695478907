import React, { useEffect } from 'react';

const BannerCard = () => {

    useEffect(() => {
        const scriptEURUSD = document.createElement('script');
        scriptEURUSD.type = 'text/javascript';
        scriptEURUSD.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
        scriptEURUSD.async = true;
        scriptEURUSD.innerHTML = JSON.stringify({
            "symbol": "FX:EURUSD",
            "width": 350,
            "height": 220,
            "locale": "in",
            "dateRange": "1D",
            "colorTheme": "light",
            "isTransparent": false,
            "autosize": false,
            "largeChartUrl": ""
        });
        document.getElementsByClassName("tradingview-widget-container__widget")[1].appendChild(scriptEURUSD);

        const scriptBTCUSD = document.createElement('script');
        scriptBTCUSD.type = 'text/javascript';
        scriptBTCUSD.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
        scriptBTCUSD.async = true;
        scriptBTCUSD.innerHTML = JSON.stringify({
            "symbol": "BITSTAMP:BTCUSD",
            "width": 350,
            "height": 220,
            "locale": "in",
            "dateRange": "1D",
            "colorTheme": "light",
            "isTransparent": false,
            "autosize": false,
            "largeChartUrl": ""
        });
        document.getElementsByClassName("tradingview-widget-container__widget")[0].appendChild(scriptBTCUSD);

        const scriptUSDINR = document.createElement('script');
        scriptUSDINR.type = 'text/javascript';
        scriptUSDINR.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
        scriptUSDINR.async = true;
        scriptUSDINR.innerHTML = JSON.stringify({
            "symbol": "FX_IDC:USDINR",
            "width": 350,
            "height": 220,
            "locale": "in",
            "dateRange": "1D",
            "colorTheme": "light",
            "isTransparent": false,
            "autosize": false,
            "largeChartUrl": ""
        });
        document.getElementsByClassName("tradingview-widget-container__widget")[2].appendChild(scriptUSDINR);



        return () => {
            var firstcard = document.getElementsByClassName("tradingview-widget-container__widget")[0];
            var secondcard = document.getElementsByClassName("tradingview-widget-container__widget")[1];
            var thirdcard = document.getElementsByClassName("tradingview-widget-container__widget")[2];
            if (firstcard) {
                firstcard.innerHTML = '';
            }
            if (secondcard) {
                secondcard.innerHTML = '';
            }
            if (thirdcard) {
                thirdcard.innerHTML = '';
            }
        };
    }, []);

    return (
        <>
            <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={0}>

                <div className="icon-bx-wraper style-1 box-hover">
                    <div class="tradingview-widget-container">
                        {/* <div className='dashboard-overlay'></div> */}
                        <div class="tradingview-widget-container__widget" ></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={1}>
                <div className="icon-bx-wraper style-1 box-hover">
                    <div class="tradingview-widget-container">
                        {/* <div className='dashboard-overlay'></div> */}
                        <div class="tradingview-widget-container__widget" ></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={2}>
                <div className="icon-bx-wraper style-1 box-hover">
                    <div class="tradingview-widget-container">
                        {/* <div className='dashboard-overlay'></div> */}
                        <div class="tradingview-widget-container__widget" ></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerCard;